import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "after-close": _vm.afterModalClose,
            "ok-text": "Add",
            "title": "Add Attribute",
            "width": 520,
            "confirm-loading": _vm.isSubmitting
          },
          on: {
            "ok": function($event) {
              return handleSubmit(_vm.submit);
            }
          },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Facility",
            "data-source": _vm.facilities,
            "source": "code",
            "rules": "required"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("facility", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Crop Code",
            "reference": "grower.common.crops",
            "source": "id",
            "source-label": _vm.genCropCodeLabel,
            "rules": "required"
          },
          on: {
            "change": _vm.onCropChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Attribute Code",
            "rules": _vm.ruleSpecialCharacter,
            "max-length": 4
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("code", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 16
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Atttribute Short Description",
            "max-length": 15
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("shortDescription", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Attribute Description",
            "max-length": 40,
            "rules": "required"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("description", $event);
            }
          }
        })], 1)], 1), _c("a-divider"), _c("a-row", {
          attrs: {
            "align": "middle",
            "gutter": 24,
            "type": "flex"
          }
        }, [_c("a-col", {
          attrs: {
            "span": 16
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Sample type",
            "data-source": _vm.sampleTypes,
            "source": "id",
            "source-label": _vm.genSampleTypeLabel,
            "rules": "required"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("sampleTypeId", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("number-input", {
          attrs: {
            "form-item": "",
            "label": "Sequence",
            "rules": "required"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("sequence", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 16
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Scale Prompt",
            "rules": "max:50"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("jagPrompt", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("checkbox-input", {
          staticClass: "pt-3",
          attrs: {
            "form-item": "",
            "label": "Manual Entry"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("manualEntry", $event);
            }
          }
        })], 1)], 1), _c("a-divider"), _c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 7
          }
        }, [_c("switch-input", {
          attrs: {
            "form-item": "",
            "label": "Warning Tolerance",
            "value": _vm.warningTolerance
          },
          on: {
            "change": _vm.onWarningToleranceChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "offset": 1,
            "span": 16
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "disabled": !_vm.warningTolerance,
            "label": "Tolerance Limit",
            "value": _vm.warningToleranceLimit,
            "rules": "number|max_value:99"
          },
          on: {
            "change": _vm.onWarningToleranceLimitChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "disabled": !_vm.warningTolerance,
            "label": "Warning Text",
            "max-length": 50,
            "value": _vm.warningText
          },
          on: {
            "change": _vm.onWarningTextChange
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateAttribute",
  inject: ["resourceProps"],
  data() {
    const [attributeProps, sampleTypeProps] = this.resourceProps;
    sampleTypeProps.crud.clearFilters();
    sampleTypeProps.crud.setPagination({});
    return {
      attributeProps,
      sampleTypeProps,
      warningText: "",
      warningTolerance: false,
      warningToleranceLimit: "",
      visible: true,
      facilities: [
        {
          code: "PK",
          name: "PK"
        }
      ],
      isSubmitting: false,
      ruleSpecialCharacter: {
        required: true,
        regexCustom: {
          regex: /^[A-Za-z0-9 ]+$/,
          message: "must not contain special character"
        }
      }
    };
  },
  computed: {
    sampleTypes() {
      return this.sampleTypeProps.crud.getList();
    }
  },
  methods: {
    genCropCodeLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    genSampleTypeLabel(option) {
      return `${option.typeCode} - ${option.description}`;
    },
    storeValue(resourceKey, value) {
      this.attributeProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    async submit() {
      this.isSubmitting = true;
      try {
        await this.attributeProps.crud.submitEntity("create");
        await this.attributeProps.crud.fetchList();
        this.visible = false;
      } finally {
        this.isSubmitting = false;
      }
    },
    onCropChange(value) {
      this.storeValue("cropId", value);
      const { crud } = this.sampleTypeProps;
      crud.setQueryString("cropId", value);
      crud.fetchList();
    },
    onWarningTextChange(value) {
      this.warningText = value;
      this.storeValue("warningText", value);
    },
    onWarningToleranceChange(value) {
      this.warningTolerance = value;
      this.warningToleranceLimit = "";
      this.warningText = "";
      this.storeValue("warningTolerance", value);
      this.storeValue("warningToleranceLimit", "");
      this.storeValue("warningText", "");
    },
    onWarningToleranceLimitChange(value) {
      this.warningToleranceLimit = value;
      this.storeValue("warningToleranceLimit", value);
    },
    afterModalClose() {
      this.$router.push(this.attributeProps.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateAttribute = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-attribute"
  }, [_c("resource", {
    attrs: {
      "name": "grower.common.facilities",
      "api-url": _vm.apiUrl,
      "resource-id-name": "code"
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl,
      "resource-id-name": "code"
    }
  }), _c("resource", {
    attrs: {
      "name": ["grower.attributes", "grower.common.sample-types"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/watties-grower/attributes"]
    }
  }, [_c("create-attribute")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateAttribute
  },
  data() {
    return {
      CreateAttribute,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
